<template>
  <div class="warehouse-double-list-page">
    <el-form :inline="true" class="bysearchForm" :model="searchForm">
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.name"
            placeholder="公司名字"
            clearable
          />
        </el-form-item>

        <el-form-item class="typeInput">
          <el-select
            v-model="searchForm.companySizeId"
            placeholder="请选择公司规模"
            clearable
          >
            <el-option
              v-for="item in companySizeList"
              :key="item.id"
              :label="item.size"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button
            v-if="isAuth('tc:company:save')"
            type="primary"
            @click="$dialog('addOrUpdate')"
            >新增</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <div class="content-container">
      <div class="profit-loss-detail-container">
        <div class="table-container">
          <el-table :data="dataList" border style="width: 100%">
            <table-tree-column
              prop="name"
              header-align="center"
              tree-key="id"
              width="150"
              label="名称"
            />
            <el-table-column
              prop="parentCompanyName"
              header-align="center"
              align="center"
              width="120"
              label="上级公司"
            />
            <el-table-column
              prop="address"
              header-align="center"
              align="center"
              width="120"
              label="公司地址"
            />
            <el-table-column
              prop="creditCode"
              header-align="center"
              align="center"
              width="120"
              label="信用代码"
            />
            <el-table-column
              prop="contact"
              header-align="center"
              align="center"
              width="120"
              label="联系人"
            />
            <el-table-column
              prop="contactMobile"
              header-align="center"
              align="center"
              width="120"
              label="联系电话"
            />
            <el-table-column
              prop="companySizeName"
              header-align="center"
              align="center"
              label="公司规模"
            >
            </el-table-column>
            <el-table-column
              prop="isMark"
              header-align="center"
              align="center"
              label="是否记账"
            >
              <template slot-scope="scope">
                <el-tag v-if="scope.row.isMark === 0" size="small">
                  不记账
                </el-tag>
                <el-tag
                  v-else-if="scope.row.isMark === 1"
                  size="small"
                  type="success"
                >
                  记账
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              width="220"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="isAuth('tc:company:info')"
                  type="text"
                  size="small"
                  @click="$dialog('addOrUpdate', scope.row.id, true)"
                  >查看</el-button
                >
                <el-button
                  v-if="isAuth('tc:company:update')"
                  type="text"
                  size="small"
                  @click="$dialog('addOrUpdate', scope.row.id)"
                  >修改</el-button
                >
                <el-button
                  v-if="isAuth('tc:company:delete')"
                  type="text"
                  size="small"
                  @click="deleteHandle(scope.row.id, scope.row.name)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-container">
          <el-pagination
            background
            @size-change="$sizeChange($event, 'dataList')"
            @current-change="$currentChange($event, 'dataList')"
            :current-page="dataListIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="dataListSize"
            :total="dataListCount"
            layout="total, sizes, prev, pager, next, jumper"
          />
        </div>
      </div>
    </div>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDataList"
    />
  </div>
</template>

<script>
import TableTreeColumn from '@/components/table-tree-column';
import AddOrUpdate from './company-add-or-update';
import { threeListMixin, listMixin, normal } from '@/mixins';
import { getCompanySizeList } from '@/utils/options';

export default {
  mixins: [threeListMixin, listMixin, normal],
  components: {
    TableTreeColumn,
    AddOrUpdate,
  },
  data() {
    return {
      searchForm: {
        name: '',
        companySizeId: '',
      },
      dataList: [],
      companySizeList: [],
      addOrUpdateVisible: false,
    };
  },
  activated() {
    this.getDataList();
    this.getCompanySizeList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/tc/company/listTree',
        params: {
          ...this.searchForm,
          page: this.dataListIndex,
          limit: this.dataListSize,
        },
        method: 'get',
      }).then(({ data }) => {
        if (data.code === 0) {
          this.dataList = this.treeDataTranslate(
            data.companyList.records,
            'id',
            'parentId',
            'childrens',
          );
          this.dataListCount = data.companyList.total;
        }
      });
    },
    // 删除
    deleteHandle(id, name) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      let names = name
        ? [name]
        : this.dataListSelections.map((item) => {
            return '"' + item.name + '"';
          });
      this.$confirm(
        `确定对${names.join(',')}进行${id ? '删除' : '批量删除'}操作?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        },
      )
        .then(() => {
          this.$http({
            url: '/tc/company/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
    getCompanySizeList() {
      getCompanySizeList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companySizeList = data.list;
        }
      });
    },
  },
};
</script>
<style scoped>
.el-popper::v-deep {
  max-height: 500px;
  max-width: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
